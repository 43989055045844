import React, { useState, useEffect } from 'react';

import Seo from "../../components/seo"
import Layout from "../../components/layout"
import Article from "../../components/article"
import useWindowSize from "../../utilt/useWindowSize"

//CSS
import { eachTitle } from "./linesAndColors.module.css"
import { desc } from "./linesAndColors.module.css"
import { desc2 } from "./linesAndColors.module.css"
import { thumNails } from "./linesAndColors.module.css"
import { thumNail } from "./linesAndColors.module.css"
import { curThumNail } from "./linesAndColors.module.css"
import { thumbImg } from "./linesAndColors.module.css"
import { modalImg } from "./linesAndColors.module.css"
import { modalArea } from "./linesAndColors.module.css"
import { isShowCSS } from "./linesAndColors.module.css"
import { modalBg } from "./linesAndColors.module.css"
import { modalWrapperWrapper } from "./linesAndColors.module.css"
import { modalWrapperScale } from "./linesAndColors.module.css"
import { modalWrapper } from "./linesAndColors.module.css"
import { modalContentCSS } from "./linesAndColors.module.css"
import { close } from "./linesAndColors.module.css"


import getLanguage from "../../utilt/getLangueage"
import getIsTouchscreen from "../../utilt/getIsTouchscreen"

//GIF
import g00 from "../../images/linesAndColors/00.gif"
import g01 from "../../images/linesAndColors/01.gif"
import g02 from "../../images/linesAndColors/02.gif"
//import g03 from "../../images/linesAndColors/03.gif"
//import g04 from "../../images/linesAndColors/04.gif"
//import g05 from "../../images/linesAndColors/05.gif"
import g06 from "../../images/linesAndColors/06.gif"
import g07 from "../../images/linesAndColors/07.gif"
import g08 from "../../images/linesAndColors/08.gif"
//import g09 from "../../images/linesAndColors/09.gif"
//import g10 from "../../images/linesAndColors/10.gif"
import g11 from "../../images/linesAndColors/11.gif"
//import g12 from "../../images/linesAndColors/12.gif"
//import g13 from "../../images/linesAndColors/13.gif"
//import g14 from "../../images/linesAndColors/14.gif"
//import g15 from "../../images/linesAndColors/15.gif"
import g16 from "../../images/linesAndColors/16.gif"
import g17 from "../../images/linesAndColors/17.gif"
//import g18 from "../../images/linesAndColors/18.gif"
//import g19 from "../../images/linesAndColors/19.gif"
import g20 from "../../images/linesAndColors/20.gif"
//import g21 from "../../images/linesAndColors/21.gif"
import g22 from "../../images/linesAndColors/22.gif"
import g23 from "../../images/linesAndColors/23.gif"
//thumb
import t00 from "../../images/linesAndColors/thumbs/00.jpg"
import t01 from "../../images/linesAndColors/thumbs/01.jpg"
import t02 from "../../images/linesAndColors/thumbs/02.jpg"
//import t03 from "../../images/linesAndColors/thumbs/03.jpg"
//import t04 from "../../images/linesAndColors/thumbs/04.jpg"
//import t05 from "../../images/linesAndColors/thumbs/05.jpg"
import t06 from "../../images/linesAndColors/thumbs/06.jpg"
import t07 from "../../images/linesAndColors/thumbs/07.jpg"
import t08 from "../../images/linesAndColors/thumbs/08.jpg"
//import t09 from "../../images/linesAndColors/thumbs/09.jpg"
//import t10 from "../../images/linesAndColors/thumbs/10.jpg"
import t11 from "../../images/linesAndColors/thumbs/11.jpg"
//import t12 from "../../images/linesAndColors/thumbs/12.jpg"
//import t13 from "../../images/linesAndColors/thumbs/13.jpg"
//import t14 from "../../images/linesAndColors/thumbs/14.jpg"
//import t15 from "../../images/linesAndColors/thumbs/15.jpg"
import t16 from "../../images/linesAndColors/thumbs/16.jpg"
import t17 from "../../images/linesAndColors/thumbs/17.jpg"
//import t18 from "../../images/linesAndColors/thumbs/18.jpg"
//import t19 from "../../images/linesAndColors/thumbs/19.jpg"
import t20 from "../../images/linesAndColors/thumbs/20.jpg"
//import t21 from "../../images/linesAndColors/thumbs/21.jpg"
import t22 from "../../images/linesAndColors/thumbs/22.jpg"
import t23 from "../../images/linesAndColors/thumbs/23.jpg"
//配列作成
const thumbsImages = [
  t00,
  t01,
  t02,
  //t03,
  //t04,
  //t05,
  t06,
  t07,
  t17,
  t08,
  //t09,
  //t10,
  //t12,
  //t13,
  //t14,
  //t15,
  t16,
  //t18,
  //t19,
  t20,
  //t21,
  t11,
  t22,
  t23,
];
const modalImages = [
  g00,
  g01,
  g02,
  //g03,
  //g04,
  //g05,
  g06,
  g07,
  g17,
  g08,
  //g09,
  //g10,
  //g12,
  //g13,
  //g14,
  //g15,
  g16,
  //g18,
  //g19,
  g20,
  //g21,
  g11,
  g22,
  g23,
];

/**
 * ページ
*/
const LinesAndColors = (props) => {
  const [lang, setLang] = useState(getLanguage());
  function setLanguage(enOrJa) {
    setLang(enOrJa);
  }
  const winSize = useWindowSize() || props.size || { width: 640, height: 948 }
  const ImagesLength = thumbsImages.length;

  /**
   * モダール用
   */
  const [isShow, setIsShow] = useState(false);
  const [picNum, setPicNum] = useState(0);

  function handleModal(event, index) {
    event.stopPropagation();
    if (typeof index == "number") {
      setPicNum(index);
    }
    setIsShow(!isShow);
  }

  function handleModalKey(event, index) {
    if (event.keyCode === 13) { //Enter key
      if (typeof index == "number") {
        setPicNum(index);
      }
      setIsShow(true);
    }
  }

  function clickModal(event) {
    event.stopPropagation();
    const modal = document.getElementById("modalContent");
    const clientRect = modal.getBoundingClientRect();

    if (event.clientX > clientRect.left + clientRect.width * 0.5) {
      setPicNum(picNum => (picNum + 1) % ImagesLength);
    } else {
      setPicNum(picNum => picNum - 1 < 0 ? ImagesLength - 1 : picNum - 1);
    }
  }

  //for touchscreen
  useEffect(() => {
    function plusPicNum() {
      setPicNum(picNum => (picNum + 1) % ImagesLength);
    }

    function minusPicNum() {
      setPicNum(picNum => picNum - 1 < 0 ? ImagesLength - 1 : picNum - 1);
    }

    let handleTouchStart, handleTouchMove, handleTouchEnd;
    if (getIsTouchscreen()) {
      let startX;
      let moveX;
      let dist = 150;
      let isStart = false;

      handleTouchStart = e => {
        //e.preventDefault();
        if (!isStart) {
          isStart = true;
          startX = e.touches[0].pageX;
        }
      }
      handleTouchMove = e => {
        //e.preventDefault();
        moveX = e.changedTouches[0].pageX;
      }
      handleTouchEnd = e => {
        if (isStart) {
          isStart = false;
          if (startX && moveX) {
            if (startX > moveX && startX > moveX + dist) { // 右から左にスワイプ
              //e.preventDefault()
              plusPicNum();
            } else if (startX < moveX && startX + dist < moveX) { // 左から右にスワイプ
              //e.preventDefault()
              minusPicNum();
            }
            startX = null;
            moveX = null;
          }
        }
      }

      document.addEventListener('touchstart', handleTouchStart);
      document.addEventListener('touchmove', handleTouchMove);
      document.addEventListener('touchend', handleTouchEnd);
    }
    return () => {
      if (getIsTouchscreen()) {
        document.removeEventListener('touchstart', handleTouchStart);
        document.removeEventListener('touchmove', handleTouchMove);
        document.removeEventListener('touchend', handleTouchEnd);
      }
    }
  }, [picNum, ImagesLength])



  //キーボード操作でモダール画像チェンジ
  useEffect(() => {
    function handlePicNumKey(event) {
      if (event.key === "ArrowRight" || event.key === "Tab") {
        let result = (picNum + 1) % ImagesLength;
        setPicNum(result);
      } else if (event.key === "ArrowLeft") {
        let result = picNum - 1 < 0 ? ImagesLength - 1 : picNum - 1;
        setPicNum(result);
      } else if (event.keyCode === 27) {
        setIsShow(false);
      }
    }

    //画像選択にfocusをシンクロさせる
    if (isShow) {
      document.getElementById(`pic${String(picNum)}`).focus();
      //console.log(document.getElementById(`pic${String(picNum)}`));
    }

    window.addEventListener('keydown', handlePicNumKey);
    return () => {
      window.removeEventListener('keydown', handlePicNumKey);
    }
  }, [picNum, isShow, ImagesLength])


  return <>
    <Seo
      title={"Lines and Colors (GIF)"}
      description={"Lines and Colors (GIF)"}
      lang={lang}
      image="https://www.drawinghell.com/ogp_images/seo_linesAndColors.jpg"
      pathname="/work/linesAndColors"
    />


    {/**
       * ページ＆サムネイル
       */}
    <Layout
      checked="worksParts"
      winSize={winSize}
    >
      <Article
        isLangBar="true"
        winSize={winSize}
        setLang={setLanguage}
        lang={props.lang}
      >
        <h1
          className={eachTitle}
        >
          Lines and Colors (GIF)
        </h1>
        <p className={desc2}>
          {lang !== "ja" ?
            `A study of "GIF Painting"` :
            `「GIF絵画」の習作`}
        </p>
        <p className={desc}>
          {lang !== "ja" ?
            `GIF animation, 2021` :
            `GIFアニメ、2021年`}
        </p>
        {/**
         * サムネイル
        */}
        <div
          className={thumNails}
        >
          {thumbsImages.map((node, index) => (
            <div
              className={isShow && picNum === index ? `${thumNail} ${curThumNail}` : thumNail}
              onClick={event => handleModal(event, index)}
              onKeyDown={event => handleModalKey(event, index)}
              role="button"
              tabIndex={0}
              id={"pic" + String(index)}
            >
              <img src={node} alt={"Lines and Colors (GIF) thumbnail"}
                className={thumbImg} />
            </div>
          ))}
        </div>
      </Article>
    </Layout>


    {/**
      * モーダル      
    */}
    <div
      className={
        isShow ? `${modalArea} ${isShowCSS}` : modalArea
      }
    >
      <div
        className={modalBg}
        onClick={event => handleModal(event)}
        onKeyDown={() => { }}
        role="button"
        tabIndex={0}
      >

        <div
          className={
            isShow ? `${modalWrapperWrapper} ${modalWrapperScale}` : modalWrapperWrapper
          }
          style={{
            width:
              (winSize.width / winSize.height) > (1354 / 1920) ? //横幅ある？
                winSize.height * (1354 / 1920) * 0.95 + "px"
                : winSize.width * 0.98 + "px"
          }}
        >

          <div
            className={modalWrapper}

          >
            <div
              className={modalContentCSS}
              onClick={event => clickModal(event)}
              onKeyDown={() => { }}
              role="button"
              tabIndex={0}
              id="modalContent"
            >
              <img src={modalImages[picNum]} alt={"GIF animation"} className={modalImg}/>

            </div>
          </div>
        </div>
      </div>
      <span
        className={close}
        onClick={event => handleModal(event)}
        onKeyDown={() => { }}
        role="button"
        tabIndex={0}
      >
        ×
      </span>
    </div>
  </>;
}

export default LinesAndColors
